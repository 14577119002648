import { React, useRef, useState, useEffect, useLayoutEffect } from "react";
import Header from "./Header";
import Footer2 from "./Footer2";
import HeaderLower from "./HeaderLower";
import "./style.css";
import "./Input_fields.css";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";
import Recaptcha from "react-recaptcha";
import RegisterService from "../services/register.service";
import LogService from "../services/log.service";
import StorageService from "../services/storage.service";
import { Navigate, Redirect, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
function RegisterForm(props) {
  const TITLE = "Casper Sweepstakes | Registration Form";
  const [fields, setFields] = useState({
    name: "",
    lname: "",
    emailid: "",
    showroom: "",
    address: "",
    phone: "",
    bed: "",
    sales_name: "",
    question: "",
  });

  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(false);
  // const [signoutTime, setSignoutTime] = useState(180000);
  const [signoutTime, setSignoutTime] = useState(900000);
  const [value_userform, setValue_userform] = useState([]);
  const [website, setWebsite] = useState(window.location.href);
  const navigate = useNavigate();

  secureLocalStorage.removeItem("registerform");

  useLayoutEffect(() => {
    const destroy = () => {
      localStorage.clear();
    };
    destroy();
  }, []);

  useEffect(() => {
    async function logCreate() {
      try {
        await LogService.createLog(website);
      } catch (e) {
        console.log(e);
      }
    }
    logCreate();
  }, [website]);

  timeout();
  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    localStorage.setItem("returnpage", true);
    localStorage.removeItem("return_page", false);
    localStorage.removeItem("registerForm");
    window.location.replace("/");
  };

  // StorageService.setRegister(false);
  localStorage.setItem("isRegister", true);

  const ref = useRef();
  useEffect(() => {
    async function test() {
      let errors = {};

      try {
        //FIXME remove this service if not used
        const response = await RegisterService.getStates();
        var value = response.data.data;
        this.setState({
          value: value,
        });
        if (response.data.status === 429 || response.data.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
        if (value.includes("redirect")) {
        } else {
          errors["questionnare"] = response.data.response;
        }
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }

    test();
  }, []);

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  // const checkedValue = (e) => {
  //   let value = "yes";
  //   if (e === false || e === "") {
  //     value = "no";
  //   }
  //   return value;
  // };

  const replaceTxt = (res) => {
    let str = res;
    if(res) {
      str = res.replace(/field/g, '');
    }
    console.log(str);
    return str;
  }

  const handleChange = (e) => {
    let { value, checked, name } = e.target;
    if (name === "showroom" && value === "true") {
      value = "";
    }
    if (name === "bed" && value === "true") {
      value = "";
    }
    if (name === "question" && value === "true") {
      value = "";
    }
    // if (name !== "question") {
      setFields((prevValue) => {
        return {
          ...prevValue,
          [name]: value,
        };
      });
    // } else {
    //   setFields((prevValue) => {
    //     return {
    //       ...prevValue,
    //       [name]: checked,
    //     };
    //   });
    // }
  };
  const saveErrors = (err) => {
    setErrors(err);
  };

  const verifyCallback = (response) => {
    let errors = {};
    if (response) {
      setCaptcha({
        captcha: true,
      });
    } else {
      setErrors(errors);
      return false;
    }
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      let data = {
        fname: fields?.name,
        lname: fields?.lname,
        email: fields?.emailid,
        showroom: fields?.showroom,
        address: fields?.address,
        phone: fields?.phone,
        bed: fields?.bed,
        sales_name: fields?.sales_name,
        question: fields?.question,
        // question: checkedValue(fields?.question),
      };

      try {
        try {
          LogService.createFormLog(website, "POST", data);
        } catch (e) {
          console.log(e);
        }

        const responseuserform = RegisterService.regData(data);
        responseuserform
          .then((response) => {
            LogService.apiResLog(website, JSON.stringify(response.data));

            var valueUserform = response?.data?.status;
            setValue_userform(valueUserform);

            // StorageService.setRegister(true);
            localStorage.removeItem("return_page");

            swal({
              title: "Successful!",
              text: "Form Submitted!",
              type: "success",
              icon: "success",
            }).then(() => {
              secureLocalStorage.setItem(
                "registerform",
                JSON.stringify({
                  name: fields.name,
                  lname: fields.lname,
                  emailid: fields.emailid,
                  showroom: fields.showroom,
                  address: fields?.address,
                  phone: fields?.phone,
                  bed: fields?.bed,
                  sales_name: fields?.sales_name,
                  question: fields?.question,
                })
              );
              navigate("/register/registration-thankyou");
            });
            if (value_userform?.includes("redirect")) {
            }
          })
          .catch((err) => {
            LogService.apiResLog(website, JSON.stringify(err.response.data));

            let errorData = {};
            errorData["name"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.fname &&
              err?.response?.data?.message.fname[0];
            errorData["lname"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.lname &&
              err?.response?.data?.message.lname[0];
            errorData["emailid"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.email &&
              err?.response?.data?.message.email[0];
            errorData["showroom"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.showroom &&
              err?.response?.data?.message.showroom[0];
            errorData["address"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.address &&
              err?.response?.data?.message.address[0];
            errorData["phone"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.phone &&
              err?.response?.data?.message.phone[0];
            errorData["bed"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.bed &&
              err?.response?.data?.message.bed[0];
            errorData["sales_name"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.sales_name &&
              err?.response?.data?.message.sales_name[0];
              errorData["question"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.question &&
              err?.response?.data?.message.question[0];
            errorData["checkederrortwo"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.size &&
              err?.response?.data?.message.size[0];
            saveErrors(errorData);
            ref?.current?.focus();
          });
      } catch (err) {
        alert(err);
        if (err.response.status === 429 || err.response.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
        let errorData = {};
        errorData["name"] = err?.response?.data?.message.fname[0];
        errorData["lname"] = err?.response?.data?.message.lname[0];
        errorData["emailid"] = err?.response?.data?.message.emailid[0];
        errorData["showroom"] = err?.response?.data?.message.showroom[0];
        // errorData["sales"] = err?.response?.data?.message.sales[0];
        errorData["address"] = err?.response?.data?.message.address[0];
        errorData["phone"] = err?.response?.data?.message.phone[0];
        errorData["bed"] = err?.response?.data?.message.bed[0];
        errorData["sales_name"] = err?.response?.data?.message.sales_name[0];
        errorData["question"] = err?.response?.data?.message.question[0];
        saveErrors(errorData);
      }
    }
  };
  const validate = (e) => {
    let errors = {};
    let isformIsValid = true;

    if (!captcha) {
      isformIsValid = false;
      errors["checkederrorcaptcha"] = "Captcha not verified";
      saveErrors(errors);
      return isformIsValid;
    }

    return isformIsValid;
  };
  // if (localStorage.getItem("return_page") !== "true") {
  //   return <Navigate to="/register" />;
  // }
  const onLoadRecaptcha = () => {};
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        <Header />
        <HeaderLower />
        <div id="main-registration-container">
          <div className="white-box form" id="font-style">
            {/* <h1>Register to score your free Reward Card!</h1>
            <hr /> */}
            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    Customer First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter First Name"
                    style={errors.name ? { border: "1px solid red" } : null}
                    name="name"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={errors.name ? ref : null}
                    value={fields.name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{replaceTxt(errors.name)}</div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Enter Last Name"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname || ""}
                    ref={errors.lname && !errors.name ? ref : null}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {replaceTxt(errors.lname && !errors.name ? errors.lname : null)}
                  </div>
                </div>

                <div className="col-md-6">
                  <label required className="formsub">
                    Customer Address <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Enter Address"
                    id="address"
                    className="searchBox_deals vc-validate"
                    autoFocus=""
                    value={fields.address || ""}
                    onChange={handleChange}
                    style={
                      errors.address && !errors.lname && !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.address && !errors.lname && !errors.name
                        ? ref
                        : null
                    }
                  />
                  <div className="errorMsg">
                    {replaceTxt(errors.address && !errors.lname && !errors.name
                      ? errors.address
                      : null)}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Phone Number# <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    style={
                      errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    name="phone"
                    placeholder="Enter Phone Number"
                    value={fields.phone || ""}
                    ref={
                      errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {replaceTxt(errors.phone &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.phone
                      : null)}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Email <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    ref={
                      errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    id="emailid"
                    placeholder="Enter Email"
                    value={fields.emailid || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {replaceTxt(errors.emailid &&
                    !errors.phone &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.emailid
                      : null)}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Showroom Location <span className="red_point">*</span>
                  </label>

                  <select
                    style={
                      errors.showroom &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.showroom &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    name="showroom"
                    id="showroom"
                    className="select_1 dropdown"
                    onChange={handleChange}
                  >
                    <option value>Select Showroom Location</option>
                    <option value="301 Fitz Henry Road Smithton, PA 15479">
                      301 Fitz Henry Road Smithton, PA 15479
                    </option>
                    <option value="600 Main Street Mt. Pleasant, PA 15666">
                      600 Main Street Mt. Pleasant, PA 15666
                    </option>
                    <option value="5280 Route 30 Greensburg, PA 15601">
                      5280 Route 30 Greensburg, PA 15601
                    </option>
                    <option value="292 Curry Hollow Rd Pleasant Hills, PA 15236">
                      292 Curry Hollow Rd Pleasant Hills, PA 15236
                    </option>
                    <option value="124 Levin Way Monroeville, PA 15146">
                      124 Levin Way Monroeville, PA 15146
                    </option>
                    <option value="1600 Washington Rd Mt Lebanon, PA 15228">
                      1600 Washington Rd Mt Lebanon, PA 15228
                    </option>
                    <option value="3664 Washington Road Mcmurray, PA 15317">
                      3664 Washington Road Mcmurray, PA 15317
                    </option>
                    <option value="5438 Baum Blvd Pittsburgh, PA 15232">
                      5438 Baum Blvd Pittsburgh, PA 15232
                    </option>
                    <option value="56 Trinity Point Dr Washington, PA 15301">
                      56 Trinity Point Dr Washington, PA 15301
                    </option>
                    <option value="6528 Steubenville Pike Pittsburgh, PA 15205">
                      6528 Steubenville Pike Pittsburgh, PA 15205
                    </option>
                    <option value="400 Chauvet Drive Pittsburgh, PA 15275">
                      400 Chauvet Drive Pittsburgh, PA 15275
                    </option>
                    <option value="10688 Perry Highway Wexford, PA 15090">
                      10688 Perry Highway Wexford, PA 15090
                    </option>
                    <option value="20012 Route 19 Cranberry, PA 16066">
                      20012 Route 19 Cranberry, PA 16066
                    </option>
                    <option value="620 Butler Crossing, Suite 5 Butler, PA 16001">
                      620 Butler Crossing, Suite 5 Butler, PA 16001
                    </option>
                    <option value="5 Sellers Drive Altoona, PA 16601">
                      5 Sellers Drive Altoona, PA 16601
                    </option>
                    <option value="138 Valley Vista Drive State College, PA 16803">
                      138 Valley Vista Drive State College, PA 16803
                    </option>
                    <option value="7200 Peach St Erie, PA 16510">
                      7200 Peach St Erie, PA 16510
                    </option>
                    <option value="7215 McKnight Rd Pittsburgh, PA 15237">
                      7215 McKnight Rd Pittsburgh, PA 15237
                    </option>
                    <option value="2390 Niles Cortland Rd Se Warren, OH 44484">
                      2390 Niles Cortland Rd Se Warren, OH 44484
                    </option>
                    <option value="6229 Promler Avenue Nw North Canton, OH 44720">
                      6229 Promler Avenue Nw North Canton, OH 44720
                    </option>
                    <option value="1061 Graham Rd Stow, OH 44224">
                      1061 Graham Rd Stow, OH 44224
                    </option>
                    <option value="23100 Broadway Ave Oakwood Village, OH 44146">
                      23100 Broadway Ave Oakwood Village, OH 44146
                    </option>
                    <option value="6061 Mayfield Rd Mayfield Hts, OH 44124">
                      6061 Mayfield Rd Mayfield Hts, OH 44124
                    </option>
                    <option value="7799 Mentor Ave Mentor, OH 44060">
                      7799 Mentor Ave Mentor, OH 44060
                    </option>
                    <option value="16105 Pearl Road Strongsville, OH 44136">
                      16105 Pearl Road Strongsville, OH 44136
                    </option>
                    <option value="16960 Sprague Road Middleburg Heights, OH 44130">
                      16960 Sprague Road Middleburg Heights, OH 44130
                    </option>
                    <option value="1801 Nagel Road Avon, OH 44011">
                      1801 Nagel Road Avon, OH 44011
                    </option>
                    <option value="1130 Boardman Poland Rd Youngstown, OH 44514">
                      1130 Boardman Poland Rd Youngstown, OH 44514
                    </option>
                  </select>
                  <div className="errorMsg">
                    {replaceTxt(errors.showroom &&
                    !errors.emailid &&
                    !errors.phone &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.showroom
                      : null)}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Sales Associate Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Sales Associate Name"
                    style={
                      errors.sales_name &&
                      !errors.showroom &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    name="sales_name"
                    // tabIndex="1"
                    id="sales_name"
                    className="searchBox_deals vc-validate"
                    ref={
                      errors.sales_name &&
                      !errors.showroom &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    value={fields.sales_name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {replaceTxt(errors.sales_name &&
                    !errors.showroom &&
                    !errors.emailid &&
                    !errors.phone &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.sales_name
                      : null)}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    What Bed Did You Test? <span className="red_point">*</span>
                  </label>

                  <select
                    style={
                      errors.bed &&
                      !errors.sales_name &&
                      !errors.showroom &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.bed &&
                      !errors.sales_name &&
                      !errors.showroom &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    name="bed"
                    id="bed"
                    className="select_1 dropdown"
                    onChange={handleChange}
                  >
                    <option value>Select Bed Type</option>
                    <option value="Casper">Casper</option>
                    <option value="Original Hybrid">Original Hybrid</option>
                    <option value="Snow">Snow</option>
                  </select>
                  <div className="errorMsg">
                    {replaceTxt(errors.bed &&
                    !errors.sales_name &&
                    !errors.showroom &&
                    !errors.emailid &&
                    !errors.phone &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.bed
                      : null)}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                  Is Your Mattress More Than 8 Years Old? <span className="red_point">*</span>
                  </label>

                  <select
                    style={
                      errors.question &&
                      !errors.bed &&
                      !errors.sales_name &&
                      !errors.showroom &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.question &&
                      !errors.bed &&
                      !errors.sales_name &&
                      !errors.showroom &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    name="question"
                    id="question"
                    className="select_1 dropdown"
                    onChange={handleChange}
                  >
                    <option value>Select Option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  <div className="errorMsg">
                    {replaceTxt(errors.question &&
                    !errors.bed &&
                    !errors.sales_name &&
                    !errors.showroom &&
                    !errors.emailid &&
                    !errors.phone &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.question
                      : null)}
                  </div>
                </div>

                {/* <li>
                  <label>
                    <input
                      type="checkbox"
                      id="question"
                      name="question"
                      className="conditions"
                      value={fields.question}
                      onChange={handleChange}
                    />
                    <span> Is Your Mattress More Than 8 Years Old.</span>
                  </label>
                </li> */}

                <br />
                <br />
                <div className="overflow-hider">
                  <Recaptcha
                    sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                    render="explicit"
                    verifyCallback={verifyCallback}
                    onloadCallback={onLoadRecaptcha}
                  />
                </div>
                <div className="errorMsg">{errors.checkederrorcaptcha}</div>

                <br />
                <div className="clearfix"></div>
              </div>

              <div class="alert alert-info">
                Limit 1 per household, while supplies last. Some restrictions
                apply, see store for detail.
              </div>
              <input
                onClick="myTimeout()"
                type="submit"
                name="submit"
                id="submit"
                value="SUBMIT"
                className="sub-btn2 button Formfirst"
              />
            </form>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}
export default RegisterForm;
